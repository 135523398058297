import React from "react";
import { useTranslation } from "react-i18next";
import withTheme from "@material-ui/core/styles/withTheme";

import TestHeader from "../../TestHeader";
import { CallQualityIco } from "../../../icons";
import { ICommonCallQulaityTestProps } from ".";
import { RenderValues } from "../../helpers/utils";
import { formatNumber, getColorValue } from "../../../../helpers/utils";
import { Typography, withStyles } from "@material-ui/core";
import ResultRow from "../UI/ResultRow";
import DonutChart from "../NewTests/UI/DonutChart";

interface IDetailedProps extends ICommonCallQulaityTestProps {
  testCall?: any;
  theme?: any;
  classes?: any;
}

const styles = (theme: any): any => ({
  testContainer: {
    display: "flex",
    flex: 1,
    gap: "16px",
    justifyContent: "center",
    padding: "12px 16px 16px 16px",
    "& > :not(:first-child)": {
      borderRightWidth: "0px",
      borderLeftWidth: "1px",
      borderLeft: `1px solid ${theme.newPalette.other.divider}`,
    },
  },
  inContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    paddingRight: "7px",
    gap: "12px",
  },
  outContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    paddingLeft: "7px",
    gap: "12px",
  },
  svgSection: {
    display: "flex",
    gap: "35px",
  },
  resultSection: {
    display: "flex",
    flexDirection: "column",
    "& > :not(:first-child)": {
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderTop: `1px solid ${theme.newPalette.other.divider}`,
    },
  },
  titleColor: {
    color: theme.newPalette.text.primary,
    textTransform: "capitalize",
  },
  chartSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  statusPosition: {
    marginTop: "-4px",
  },
});

const Detailed = (props: IDetailedProps) => {
  const { theme, testCall, config, mainColor, currentThresholds, classes } = props;

  const { avgJit, avgJitOut, avgPl, avgOutPl, avgRtt, mark, error, meta, mosInbound, transportProtocol } = testCall;

  const colorTypes = {
    errorColor: config?.useNewWidgetDesign ? theme.newPalette.error.main : theme.customColors.errorColor,
    warningColor: config?.useNewWidgetDesign ? theme.newPalette.warning.main : theme.customColors.warningColor,
    successColor: config?.useNewWidgetDesign ? theme.newPalette.text.primary : theme.customColors.successColor,
  };

  const defaultProps = {
    mainColor,
    color: config?.useNewWidgetDesign ? theme.newPalette.text.primary : theme.customColors.successColor,
    formatting: formatNumber,
  };

  const chartLabelColor = theme.newPalette.text.primary;

  let overall =
    currentThresholds && currentThresholds.mark
      ? mark > currentThresholds.mark.warning
        ? "Excellent"
        : mark > currentThresholds.mark.error
        ? "Good"
        : mark <= currentThresholds.mark.error
        ? "Fair"
        : mark > 1
        ? "Poor"
        : "Bad"
      : mark > 4
      ? "Excellent"
      : mark > 3
      ? "Good"
      : mark > 2
      ? "Fair"
      : mark > 1
      ? "Poor"
      : "Bad";

  // const overall2 =
  //   mark2 > 4 ? "Excellent" : mark2 > 3 ? "Good" : mark2 > 2 ? "Fair" : mark2 > 1 ? "Poor" : "Bad";

  const overall2 =
    currentThresholds && currentThresholds.mark
      ? mosInbound > currentThresholds.mark.warning
        ? "Excellent"
        : mosInbound > currentThresholds.mark.error
        ? "Good"
        : mosInbound <= currentThresholds.mark.error
        ? "Fair"
        : mosInbound > 1
        ? "Poor"
        : "Bad"
      : mosInbound > 4
      ? "Excellent"
      : mosInbound > 3
      ? "Good"
      : mosInbound > 2
      ? "Fair"
      : mosInbound > 1
      ? "Poor"
      : "Bad";

  // this is outbound mos

  const mosValue = mark && mark != "N/A" ? `${mark.toFixed(1)} <span className="result-extra">(${overall})</span>` : "N/A";

  // const mosValue2 = mark2
  //   ? `${mark2.toFixed(1)} <span className="result-extra">(${overall2})</span>`
  //   : "No Data";

  // this is inbound mos

  const mosValue2 = mosInbound && mosInbound != "N/A" ? `${mosInbound.toFixed(1)} <span className="result-extra">(${overall2})</span>` : "N/A";

  const calculateMos = (value: any) => {
    return value !== null && value !== undefined && value !== "N/A" ? value.toFixed(1) : "N/A";
  };

  const { t } = useTranslation(["common", "tests"]);

  return (
    <TestHeader
      icon={<CallQualityIco />}
      title={t("tests:call-quality.title")}
      tooltip={t("tests:call-quality.tooltip")}
      error={error}
      knowledgeUrl={props.knowledgeUrl}
      estimateTime={props.estimateTime}
      testName={props.testName}
      errorsCount={meta?.errorsCount}
      badge={transportProtocol}
    >
      {config?.useNewWidgetDesign ? (
        <div className={classes.testContainer}>
          <div className={classes.inContainer}>
            <div className={classes.svgSection}>
              <Typography variant="body1" className={classes.titleColor}>
                {t("in")}
              </Typography>
              <div className={classes.chartSection}>
                <DonutChart
                  value={calculateMos(mosInbound)}
                  small
                  parentId="CQC-r-MOSScore"
                  childId="CQC-c-mosValue2"
                  textColor={
                    currentThresholds && currentThresholds.mark
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: mosInbound,
                            warningValue: currentThresholds.mark.warning,
                            errorValue: currentThresholds.mark.error,
                            warningValueMax: currentThresholds.mark.warningMax,
                            warningValueMin: currentThresholds.mark.warningMin,
                          },
                          currentThresholds.mark.isLess
                        )
                      : defaultProps.color
                  }
                />
                <Typography
                  variant="caption"
                  className={classes.statusPosition}
                  style={{
                    opacity: mosInbound === "N/A" ? "0" : "100",
                    color: chartLabelColor,
                  }}
                >
                  {overall2}
                </Typography>
              </div>
            </div>
            <div className={classes.resultSection}>
              <ResultRow callQuality={true} label={t("Round Trip")} value="-" color={defaultProps.color} size="small" />
              <ResultRow
                callQuality={true}
                label={t("Packet Loss")}
                value={avgPl}
                formatting={true}
                unit="%"
                color={
                  currentThresholds && currentThresholds.avgPl
                    ? getColorValue(
                        {
                          ...colorTypes,
                          value: avgPl,
                          warningValue: currentThresholds.avgPl.warning,
                          errorValue: currentThresholds.avgPl.error,
                        },
                        currentThresholds.avgPl.isLess
                      )
                    : defaultProps.color
                }
                size="small"
                id="CQC-r-packetLoss"
                valueId="CQC-c-avgPl"
              />
              <ResultRow
                callQuality={true}
                label={t("jitter")}
                value={avgJit}
                color={
                  currentThresholds && currentThresholds.avgJit
                    ? getColorValue(
                        {
                          ...colorTypes,
                          value: avgJit,
                          warningValue: currentThresholds.avgJit.warning,
                          errorValue: currentThresholds.avgJit.error,
                        },
                        currentThresholds.avgJit.isLess
                      )
                    : defaultProps.color
                }
                unit="ms"
                size="small"
                id="CQC-r-jitter"
                valueId="CQC-c-avgJit"
              />
            </div>
          </div>
          <div className={classes.outContainer}>
            <div className={classes.svgSection}>
              <Typography variant="body1" className={classes.titleColor}>
                {t("out")}
              </Typography>
              <div className={classes.chartSection}>
                <DonutChart
                  value={calculateMos(mark)}
                  small
                  parentId="CQC-r-MOSScoreOut"
                  childId="CQC-c-mosValue"
                  textColor={
                    currentThresholds && currentThresholds.mark
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: mark,
                            warningValue: currentThresholds.mark.warning,
                            errorValue: currentThresholds.mark.error,
                            warningValueMax: currentThresholds.mark.warningMax,
                            warningValueMin: currentThresholds.mark.warningMin,
                          },
                          currentThresholds.mark.isLess
                        )
                      : defaultProps.color
                  }
                />
                <Typography
                  variant="caption"
                  className={classes.statusPosition}
                  style={{
                    opacity: mark === "N/A" ? "0" : "100",
                    color: chartLabelColor,
                  }}
                >
                  {overall}
                </Typography>
              </div>
            </div>
            <div className={classes.resultSection}>
              <ResultRow
                callQuality={true}
                label={t("Round Trip")}
                value={avgRtt}
                unit="ms"
                color={
                  currentThresholds && currentThresholds.avgRtt
                    ? getColorValue(
                        {
                          ...colorTypes,
                          value: avgRtt,
                          warningValue: currentThresholds.avgRtt.warning,
                          errorValue: currentThresholds.avgRtt.error,
                          warningValueMax: currentThresholds.avgRtt.warningMax,
                          warningValueMin: currentThresholds.avgRtt.warningMin,
                        },
                        currentThresholds.avgRtt.isLess
                      )
                    : defaultProps.color
                }
                size="small"
                id="CQC-r-roundTrip"
                valueId="CQC-c-avgRtt"
              />
              <ResultRow
                callQuality={true}
                label={t("Packet Loss")}
                value={avgOutPl}
                formatting={true}
                unit="%"
                color={
                  currentThresholds && currentThresholds.avgPl
                    ? getColorValue(
                        {
                          ...colorTypes,
                          value: avgOutPl,
                          warningValue: currentThresholds.avgPl.warning,
                          errorValue: currentThresholds.avgPl.error,
                        },
                        currentThresholds.avgPl.isLess
                      )
                    : defaultProps.color
                }
                size="small"
                id="CQC-r-packetLossOut"
                valueId="CQC-c-avgOutPl"
              />
              <ResultRow
                callQuality={true}
                label={t("jitter")}
                value={avgJitOut}
                color={
                  currentThresholds && currentThresholds.avgJit
                    ? getColorValue(
                        {
                          ...colorTypes,
                          value: avgJitOut,
                          warningValue: currentThresholds.avgJit.warning,
                          errorValue: currentThresholds.avgJit.error,
                        },
                        currentThresholds.avgJit.isLess
                      )
                    : defaultProps.color
                }
                unit="ms"
                size="small"
                id="CQC-r-jitterOut"
                valueId="CQC-c-avgJitOut"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={`tests-card-content tst-call-quality`}>
          <table>
            <tbody>
              <tr className="two-cols-mode-headers">
                <td></td>
                <td>{t("in")}</td>
                <td>{t("out")}</td>
              </tr>
              <tr className="tst-mos" id="CQC-r-MOSScore">
                <td>{t("MOS Score")}</td>
                <td id="CQC-c-mosValue2">
                  {
                    <RenderValues
                      {...defaultProps}
                      value={mosValue2}
                      color={
                        currentThresholds && currentThresholds.mark
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: mosInbound,
                                warningValue: currentThresholds.mark.warning,
                                errorValue: currentThresholds.mark.error,
                                warningValueMax: currentThresholds.mark.warningMax,
                                warningValueMin: currentThresholds.mark.warningMin,
                              },
                              currentThresholds.mark.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  }
                </td>
                <td id="CQC-c-mosValue">
                  {
                    <RenderValues
                      {...defaultProps}
                      value={mosValue}
                      color={
                        currentThresholds && currentThresholds.mark
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: mark,
                                warningValue: currentThresholds.mark.warning,
                                errorValue: currentThresholds.mark.error,
                                warningValueMax: currentThresholds.mark.warningMax,
                                warningValueMin: currentThresholds.mark.warningMin,
                              },
                              currentThresholds.mark.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  }
                </td>
              </tr>
              <tr className="tst-round-trip" id="CQC-r-roundTrip">
                <td>{t("Round Trip")}</td>
                <td></td>
                <td id="CQC-c-avgRtt">
                  <RenderValues
                    {...defaultProps}
                    formatting={(value: number) => `${value} <span>ms</span>`}
                    value={avgRtt}
                    color={
                      currentThresholds && currentThresholds.avgRtt
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: avgRtt,
                              warningValue: currentThresholds.avgRtt.warning,
                              errorValue: currentThresholds.avgRtt.error,
                              warningValueMax: currentThresholds.avgRtt.warningMax,
                              warningValueMin: currentThresholds.avgRtt.warningMin,
                            },
                            currentThresholds.avgRtt.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              </tr>
              <tr className="tst-packet-loss" id="CQC-r-packetLoss">
                <td>{t("Packet Loss")}</td>
                <td id="CQC-c-avgPl">
                  <RenderValues
                    {...defaultProps}
                    formatting={(value: number) => `${value.toFixed(1)}%`}
                    value={avgPl}
                    color={
                      currentThresholds && currentThresholds.avgPl
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: avgPl,
                              warningValue: currentThresholds.avgPl.warning,
                              errorValue: currentThresholds.avgPl.error,
                            },
                            currentThresholds.avgPl.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
                <td id="CQC-c-avgOutPl">
                  <RenderValues
                    {...defaultProps}
                    formatting={(value: number) => `${value.toFixed(1)}%`}
                    value={avgOutPl}
                    color={
                      currentThresholds && currentThresholds.avgPl
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: avgOutPl,
                              warningValue: currentThresholds.avgPl.warning,
                              errorValue: currentThresholds.avgPl.error,
                            },
                            currentThresholds.avgPl.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              </tr>
              <tr className="tst-jitter" id="CQC-r-jitter">
                <td>{t("jitter")}</td>
                <td id="CQC-c-avgJit">
                  <div className="value-row-pair">
                    <RenderValues
                      value={avgJit}
                      {...defaultProps}
                      color={
                        currentThresholds && currentThresholds.avgJit
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: avgJit,
                                warningValue: currentThresholds.avgJit.warning,
                                errorValue: currentThresholds.avgJit.error,
                              },
                              currentThresholds.avgJit.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </div>
                </td>
                <td id="CQC-c-avgJitOut">
                  <div className="value-row-pair">
                    <RenderValues
                      value={avgJitOut}
                      {...defaultProps}
                      color={
                        currentThresholds && currentThresholds.avgJit
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: avgJitOut,
                                warningValue: currentThresholds.avgJit.warning,
                                errorValue: currentThresholds.avgJit.error,
                              },
                              currentThresholds.avgJit.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </TestHeader>
  );
};

export default withTheme()(withStyles(styles)(Detailed));
